@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}
body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.content {
  color: rgba(0, 0, 0, 0.6);
  padding: 0 15%;
  margin-top: 80px;
}
@media screen and (max-width: 952px) {
  .header .search {
    display: none !important;
  }
}
@media screen and (max-width: 500px) {
  .pagination {
    font-size: 12px !important;
  }
  .content {
    padding: 0 !important;
  }
  .header img {
    display: none !important;
    min-height: 81px !important;
  }
  .welcomer {
    padding: 4% 10% !important;
  }
  .welcomer h1 {
    font-size: 64px !important;
  }
  .header {
    height: 81px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    justify-content: center !important;
  }
  .search {
    flex-direction: column;
  }
  .search .customInput {
    width: 100%;
    margin-right: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 360px) {
  .navbarlist li ul {
    margin-right: 1rem !important;
  }
}

.welcomer {
  background-image: linear-gradient(black, black),
    url('https://images.unsplash.com/photo-1616450030149-26a8bc9dd54f?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1950&q=80');
  height: auto;
  background-position: center;
  background-size: cover;
  background-blend-mode: saturation;

  color: white;
  padding: 4% 50% 4% 10%;
}
.banner {
  margin-top: 80px;
}
.welcomer h1 {
  font-size: 72px;
  margin-bottom: 3rem;
}
.welcomer p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
.welcomer img {
}

.discoverButton {
  text-align: center;
  vertical-align: middle;
  margin-top: 5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 28px;
  border: none;
  outline: none;
  font-weight: 700;
  font-size: 18px;
  color: #e11d39;
  transition: all ease 0.12s;
}
.discoverButton:hover {
  cursor: pointer;
  color: white;
  background-color: #e11d39;
}
.arrowico {
  fill: #e11d39;
  margin-right: 10px;
}
.discoverButton:hover .arrowico {
  fill: white;
}
.centerialize {
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchbanner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  height: auto;
}

.header {
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  color: white;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  position: fixed;
  z-index: 100;
  top: 0;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbarlist ul {
  list-style-type: none;
  display: flex;
  font-weight: 600;
  font-size: 16px;
}

.navbarlist ul li {
  margin-right: 2rem;
  color: #e11d39;
}
.navbarlist ul li a {
  text-decoration: none;
  font-weight: 700;
  color: #e11d39;
  transition: all ease 0.1s;
}
.navbarlist ul li a:visited {
  text-decoration: none;
  color: #e11d39;
}
.navbarlist ul li a:hover {
  color: black;
  cursor: pointer;
}

.custom-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.4);
  background-color: #f0f0f0;
  z-index: 999;
}
.dropdown-button {
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background-color: #f0f0f0;
  margin-right: 20px;
  color: black;
  font-weight: 600;
  font-size: 14px;
}

.custom-dropdown-item {
  margin-bottom: 5px;
  padding: 0.5rem 1rem;
  font-size: 12px;
  color: black;
  font-weight: 600;
}
.custom-dropdown-item:hover {
  background-color: #c9c9c9;
  cursor: pointer;
}

.search {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: rgb(240, 240, 240);
  border-radius: 16px;
}
.customInput {
  font-weight: 600;
  font-size: 14px;
  border: none;
  outline: none;
  margin-right: 1rem;
  background-color: rgb(240, 240, 240);
}
.icon {
  cursor: pointer;
}
.customSelect {
  font-size: 14px;
  background-color: rgb(240, 240, 240);
  margin-right: 2rem;
  font-weight: 600;
  border: none;
  outline: none;
}
option {
  border-radius: 50%px;
}

.imageCard {
  position: relative;
  padding: 0.5rem;
  transition: all 0.18s;
  width: auto;
}
.imageCard img {
  border-radius: 16px;
  height: auto;
  min-height: 180px;

  -webkit-animation-name: skeleton;

          animation-name: skeleton;
  -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
}
.imageCard:hover {
  transform: scale(1.02);
  cursor: pointer;
}
.iconBox {
  position: absolute;
  padding: 10px 10px 5px 10px;
  z-index: 99;
  border-radius: 50%;
  background-color: rgba(245, 245, 245, 1);
  top: 20px;
  left: 20px;
  transition: all 0.38s;
}

.icon {
  fill: #e11d39;
}

.iconBox:hover {
  cursor: pointer;
  background-color: rgb(240, 240, 240);
}

.iconactive {
  position: absolute;
  padding: 10px 10px 5px 10px;
  z-index: 99;
  border-radius: 50%;
  background-color: #e11d39;
  top: 20px;
  left: 20px;
  transition: all 0.38s;
}
.iconactive .icon {
  fill: white;
}

.iconactive:hover {
  cursor: pointer;
  background-color: #bb1931;
}

@-webkit-keyframes skeleton {
  0% {
    background-color: rgb(220, 220, 220);
  }
  50% {
    background-color: rgb(245, 245, 245);
  }
  100% {
    background-color: rgb(220, 220, 220);
  }
}

@keyframes skeleton {
  0% {
    background-color: rgb(220, 220, 220);
  }
  50% {
    background-color: rgb(245, 245, 245);
  }
  100% {
    background-color: rgb(220, 220, 220);
  }
}

.masonry {
  -webkit-column-count: 3;
          column-count: 3;
  padding: 1rem;
}
.masonry > * {
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
          break-inside: avoid;
}

@media screen and (max-width: 1024px) {
  .masonry {
    -webkit-column-count: 2;
            column-count: 2;
  }
}
@media screen and (max-width: 542px) {
  .masonry {
    -webkit-column-count: 1;
            column-count: 1;
  }
  .content {
    padding: 0 !important;
  }
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 1rem;
  font-size: 16px;
  font-weight: 600;
  color: #e11d39;
  outline: none;
}
.pagination li {
  margin-right: 10px;
  padding: 0.5rem 1rem;
  border-radius: 8px;

  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(240, 240, 240);
  outline: none;
}
.pagination li:hover {
  cursor: pointer;
  color: white;
  background-color: #e11d39;
  border: 2px solid #e11d39;
}
.active {
  color: white;
  background-color: #e11d39 !important;
  border: 2px solid #e11d39 !important;
}


