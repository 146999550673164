.imageCard {
  position: relative;
  padding: 0.5rem;
  transition: all 0.18s;
  width: auto;
}
.imageCard img {
  border-radius: 16px;
  height: auto;
  min-height: 180px;

  animation-name: skeleton;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}
.imageCard:hover {
  transform: scale(1.02);
  cursor: pointer;
}
.iconBox {
  position: absolute;
  padding: 10px 10px 5px 10px;
  z-index: 99;
  border-radius: 50%;
  background-color: rgba(245, 245, 245, 1);
  top: 20px;
  left: 20px;
  transition: all 0.38s;
}

.icon {
  fill: #e11d39;
}

.iconBox:hover {
  cursor: pointer;
  background-color: rgb(240, 240, 240);
}

.iconactive {
  position: absolute;
  padding: 10px 10px 5px 10px;
  z-index: 99;
  border-radius: 50%;
  background-color: #e11d39;
  top: 20px;
  left: 20px;
  transition: all 0.38s;
}
.iconactive .icon {
  fill: white;
}

.iconactive:hover {
  cursor: pointer;
  background-color: #bb1931;
}

@keyframes skeleton {
  0% {
    background-color: rgb(220, 220, 220);
  }
  50% {
    background-color: rgb(245, 245, 245);
  }
  100% {
    background-color: rgb(220, 220, 220);
  }
}
