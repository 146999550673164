@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}
body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.content {
  color: rgba(0, 0, 0, 0.6);
  padding: 0 15%;
  margin-top: 80px;
}
@media screen and (max-width: 952px) {
  .header .search {
    display: none !important;
  }
}
@media screen and (max-width: 500px) {
  .pagination {
    font-size: 12px !important;
  }
  .content {
    padding: 0 !important;
  }
  .header img {
    display: none !important;
    min-height: 81px !important;
  }
  .welcomer {
    padding: 4% 10% !important;
  }
  .welcomer h1 {
    font-size: 64px !important;
  }
  .header {
    height: 81px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    justify-content: center !important;
  }
  .search {
    flex-direction: column;
  }
  .search .customInput {
    width: 100%;
    margin-right: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 360px) {
  .navbarlist li ul {
    margin-right: 1rem !important;
  }
}
