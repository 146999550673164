.custom-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.4);
  background-color: #f0f0f0;
  z-index: 999;
}
.dropdown-button {
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background-color: #f0f0f0;
  margin-right: 20px;
  color: black;
  font-weight: 600;
  font-size: 14px;
}

.custom-dropdown-item {
  margin-bottom: 5px;
  padding: 0.5rem 1rem;
  font-size: 12px;
  color: black;
  font-weight: 600;
}
.custom-dropdown-item:hover {
  background-color: #c9c9c9;
  cursor: pointer;
}
