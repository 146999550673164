.header {
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  color: white;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  position: fixed;
  z-index: 100;
  top: 0;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbarlist ul {
  list-style-type: none;
  display: flex;
  font-weight: 600;
  font-size: 16px;
}

.navbarlist ul li {
  margin-right: 2rem;
  color: #e11d39;
}
.navbarlist ul li a {
  text-decoration: none;
  font-weight: 700;
  color: #e11d39;
  transition: all ease 0.1s;
}
.navbarlist ul li a:visited {
  text-decoration: none;
  color: #e11d39;
}
.navbarlist ul li a:hover {
  color: black;
  cursor: pointer;
}
