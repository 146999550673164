.search {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: rgb(240, 240, 240);
  border-radius: 16px;
}
.customInput {
  font-weight: 600;
  font-size: 14px;
  border: none;
  outline: none;
  margin-right: 1rem;
  background-color: rgb(240, 240, 240);
}
.icon {
  cursor: pointer;
}
.customSelect {
  font-size: 14px;
  background-color: rgb(240, 240, 240);
  margin-right: 2rem;
  font-weight: 600;
  border: none;
  outline: none;
}
option {
  border-radius: 50%px;
}
